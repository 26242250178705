// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Hero from 'components/Contentful/Hero'
import HowTo from 'components/Contentful/HowTo'
import IntroAndPicture from 'components/Contentful/IntroAndPicture'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Erfahrungsbericht von Familie Wülfert"
        meta={[
          { name: 'description', content: 'Die Familie Wülfert aus Dornach SO hat ihre aussen aufgestellte Luft/Wasser-Wärmepumpe von NIBE aufgehübscht. Gleich reinschauen!' },
          { name: 'keywords', content: 'Erfahrungsbericht Wärmepumpe Photovoltaik' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='42eUNW7h6I13r2eAM726Re-3pTkRQcYvxeo6impazXSpR'
          id='familie-wuelfert-dornach-so'
        >
          <Hero
            {...{
    "type": "hero",
    "id": "3pTkRQcYvxeo6impazXSpR",
    "updatedAt": "2021-02-11T16:17:43.835Z",
    "title": "FAMILIE WÜLFERT, DORNACH SO",
    "size": "Groß",
    "teaser": "Neunzehn Jahre lang war die Erdgas-Heizung der Familie Wülfert im Einsatz. Das Einfamilienhaus hatte die Familie im Jahr 2018 mit der alten Heizung zusammen übernommen. Bevor der kostenintensive Wärmetauscher hätte ersetzt werden müssen, fing sie nach einer Alternativlösung für eine zukunftsorientierte Heizenergie-Erzeugung zu suchen. Doch rasch war klar: Es wird wieder eine Wärmepumpe. Denn in ihrem vorherigen Eigenheim hatten sie die Vorteile einer Wärmepumpe schon selbst erfahren dürfen.",
    "image": {
        "description": "Bild der neuen Photovoltaik-Anlage der Familie Wülfert",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/2o5dLFX63A2fUHsm9rFEJe/d6ceb61b9a0bfb1a02e66076be9d0651/3---Bild-Hero---formatiert.png"
    },
    "links": [
        {
            "type": "link",
            "id": "5Rv4CldRK0aI4UGakKsISC",
            "updatedAt": "2022-04-01T07:44:51.656Z",
            "label": "Zur Referenz-Übersicht",
            "page": {
                "slug": "referenzen"
            }
        }
    ],
    "anchorName": "familie-wuelfert-dornach-so"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='42eUNW7h6I13r2eAM726Re-3qXU28ZwoMC25cJkudo5rA'
          id=''
        >
          <HowTo
            {...{
    "type": "howTo",
    "id": "3qXU28ZwoMC25cJkudo5rA",
    "updatedAt": "2021-02-10T11:00:26.597Z",
    "title": "«Dass das neue Heizsystem eine Kombination aus Wärmepumpe und Photovoltaikanlage wird, war für uns klar»",
    "steps": [
        {
            "type": "howToStep",
            "id": "PXs69xxQYQqlvoONFGtzn",
            "updatedAt": "2021-06-11T07:38:52.063Z",
            "title": "Dass das neue Heizsystem wieder eine Wärmepumpe wird, hatte Martin Wülfert schon immer gewusst.",
            "description": "<p>Doch von welchem Anbieter stand noch offen, sagt er. «Also beschlossen meine Frau und ich, uns von diversen Anbietern beraten zu lassen und erhielten viele Offerten.» Bei so vielen Wärmepumpen-Anbietern war für die Entscheidung das breite Produktportfolio und schliesslich auch die Sympathie des Unternehmens ausschlaggebend. <strong>Martin Weber, Heizungsexperte bei der Heizungsmacher AG hat das richtige Trio für das Eigenheim der Familie Wülfert gefunden. Eine <a href=\"https://www.heizungsmacher.ch/wissen/waermepumpe-und-photovoltaik-ein-wirtschaftliches-duo/\">Wärmepumpe kombiniert mit einer Photovoltaikanlage</a> und einem Batteriespeicher. Schliesslich liess sich Familie Wülfert sich von der transparenten und den Bedürfnissen entsprechenden Offerte überzeugen</strong>.</p>\n",
            "image": {
                "description": "Bild von der neuen Photovoltaik-Anlage der Familie Wülfert",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/DObe9F3z8VH4M4hCWGqQL/a7df5afd1e3b9e5e9a81a672d668f8a7/4---Bild-PV-formatiert-Anleitungsschritt.png"
            }
        },
        {
            "type": "howToStep",
            "id": "2jfzZzBQByKuTDdzA7sSUC",
            "updatedAt": "2021-06-11T07:39:44.921Z",
            "title": "Das Elektroauto kostenlos auftanken",
            "description": "<p>Martin Wülfert ist positiv überrascht: <strong>«Im Sommer produzieren wir deutlich mehr Strom als wir im Haus nutzen und können so unser Elektroauto tatsächlich kostenlos auftanken.» «Im Herbst sind es immer noch über 50% Energieersparnis dank der Synergie von Wärmepumpe und Photovoltaikanlage»</strong> fügt er hinzu. Ein weiterer Aspekt hat ihn ebenfalls beeindruckt: «Das komplette Heizsystem wie auch die Photovoltaikproduktion und Stromnutzung ist auch während Abwesenheiten immer per Smartphone per App überwachbar.»</p>\n<p><strong>Ursprünglich dachte Familie Wülfert daran, eine <a href=\"https://www.heizungsmacher.ch/wissen/wie-funktioniert-eine-erdsondenbohrung/\">Erdwärmepumpe</a> einbauen zu lassen. Mithilfe der Heiz Experten der Heizungsmacher AG war aber schnell klar, dass der gesamte Garten hätte umgegraben werden müssen. Daher hat sich die Familie Wülfert mit gutem Gewissen für eine <a href=\"https://www.heizungsmacher.ch/waermepumpen/luft-wasser-aussenaufstellung/\">aussen aufgestellte Luft/Wasser-Wärmepumpe</a> entschieden.</strong></p>\n<p>Herr Wülfert stellt fest, dass der Platzbedarf für den Wärmespeicher und die Speicherbatterie für die Photovoltaikanlage höher ist als bei der vorherigen Erdgas-Heizung. Der Grund dafür ist, dass Erdgas-Therme an der Wand befestigt werden können. Der Wärmespeicher braucht hingegen einen Platzbedarf von ungefähr 1 m2. Für das Paar kein Problem, da ihr die vielen Vorteile einer Wärmepumpe bewusst sind.</p>\n",
            "image": {
                "description": "Bild der neuen E3/DC Wallbox der Familie Wülfert",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/4IX2OohjuBm9FdorwI0k8u/66690e466ea761498f67596f1b1fc274/5---Bild-Ladestation---Anleitungsschritt.png"
            }
        },
        {
            "type": "howToStep",
            "id": "1pr3rRZUckCVDjlSMby3M1",
            "updatedAt": "2021-02-11T11:03:59.710Z",
            "title": "Schonmal eine rote Wärmepumpe gesehen?",
            "description": "<p>Herr Wülfert lacht: «Wir wollten das Gesamtbild unseres Hauses nicht gross verändern und haben daher <strong>die Wärmepumpe mit der gleichen Farbe des Hauses gestrichen.»</strong> Und wir vom Heizungsmacher-Team bestätigen – es sieht wirklich toll aus.</p>\n",
            "image": {
                "description": "Bild von der neuen Wärmepumpe der Familie Wülfert",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/1G7VcDt4cI0Zj3mhNxVBUw/a63ad864132baa3a2ddd71988ad6011e/2---Bild-Anleitungsschritt-formatiert.png"
            }
        },
        {
            "type": "howToStep",
            "id": "48ueMFB1NgVVnZ9aybf7gA",
            "updatedAt": "2021-06-11T07:40:49.100Z",
            "title": "Rundum transparent und zuverlässig",
            "description": "<p>Martin Wülfert ist glücklich: «Das Projekt verlief nahezu wie geplant.» Am Anfang der Bauplanung erhielt Familie Wülfert einen exakten Terminplan über die gesamte Bauphase. Erfreut sagt er: «Das Heizungsmacher-Team war ausnahmslos freundlich und die Arbeiten der lokalen Partner wurden sauber und gründlich erledigt.» Dass bei einem grossen Projekt wie diesem ein paar Hindernisse auftauchen, ist für Familie Wülfert verständlich. Er fügt hinzu: «Wichtig ist nur, wie man damit umgeht. Wir waren mit den Problemlösungen der Heizungsmacher stets zufrieden.»</p>\n<p>Durch die Komplett-Lösung der <a href=\"https://www.heizungsmacher.ch/\">Heizungsmacher AG</a> hat Familie Wülfert einen grossen Schritt in die Unabhängigkeit gemacht. <strong>Dank dem zeitgleichen Einbau der aussen aufgestellten <a href=\"https://www.heizungsmacher.ch/wissen/was-ist-eine-luft-waermepumpe/\">Luft/Wasser-Wärmepumpe</a> und der Photovoltaikanlage kann Familie Wülfert von Anfang an die Synergie der beiden ökologischen Anlagen nutzen und zur Reduktion des <a href=\"https://www.heizungsmacher.ch/wissen/oelheizung-ersetzen-informationen-foerderungen-regelungen/#steigende-co2-abgaben-hohe-betriebskosten\">CO2-Ausstosses</a> einen wesentlichen Beitrag leisten</strong>.</p>\n<p>Bei dieser Gelegenheit haben wir als Heizungsmacher-Team die konstruktiven Verbesserungsvorschläge von Herr Wülfert gerne aufgenommen und nutzen diese um unsere Arbeitsprozesse zu verbessern. Das Fazit zum Schluss des Gesprächs fällt eindeutig aus: Familie Wülfert fühlte sich vom ersten Beratungsgespräch bis zum Schluss der Bauplanung und darüber hinaus rundum wohl.»</p>\n",
            "image": {
                "description": "Bild vom Zuhause der Familie Wülfert",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/4rYXtYybuBA04TEMLk1J6Y/ff1342c2286308718ac8b8be7c0b18e2/6---Bild-Geb__ude---formatiert---Anleitungsschritt.png"
            }
        }
    ]
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='42eUNW7h6I13r2eAM726Re-78zaJicAy8FBTMC5E1q6yi'
          id='feedback-familie-wuelfert'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "78zaJicAy8FBTMC5E1q6yi",
    "updatedAt": "2022-09-20T11:49:31.464Z",
    "title": "Feedback Familie Wülfert",
    "anchorName": "feedback-familie-wuelfert",
    "text": "<p><em>\"Wertes Heizungsmacher/Solarmacher Team,\nDanke nochmals für die sehr kompetente Beratung und die nahezu reibungslose Umsetzung unserer PV Anlage, Batteriespeicher sowie Luft-Wasser Wärmepumpe. Die Ausführung der Arbeiten ist sehr sauber erfolgt und Ihr Team war auch nach der Installation bei Problemen immer ansprechbar sowie Lösungen schnell erarbeitet. Wir sind mit unserer Anlage sehr glücklich und von März bis Oktober vollständig autark. Besten Dank für alles!\"</em></p>\n<p>Heizungsmacher-Team: <em>Sehr geehrter Herr Wülfert. Vielen Dank für Ihr Feedback und Ihre Weiterempfehlung. Es freut uns sehr zu hören, dass Sie mit unserer Komplett-Lösung (Wärmepumpe &#x26; Photovoltaik) zufrieden waren und Sie von der Eigenverbrauchsoptimierung profitieren können. Wir wünschen Ihnen weiterhin viel Freude an Ihren Anlagen. Beste Grüsse - Ihr Heizungsmacher-Team</em></p>\n",
    "image": {
        "description": "Kundenfeedback: 5 Sterne-Bewertung",
        "contentType": "image/svg+xml",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/7dFyVjY2BukXpteLJih95X/047c09b3094ac7fb446dc6015dcddd65/5_Sterne-Bewertung.svg"
    }
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='42eUNW7h6I13r2eAM726Re-5jl6yjDwGax05p7DpzlEBw'
          id='wuenschen-sie-eine-beratung'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "5jl6yjDwGax05p7DpzlEBw",
    "updatedAt": "2023-08-30T09:41:16.098Z",
    "title": "Wünschen Sie eine Beratung?",
    "anchorName": "wuenschen-sie-eine-beratung",
    "text": "<p>Sie interessieren sich für eine Wärmepumpe und sind noch nicht sicher, ob dies die richtige Wahl ist? Besuchen Sie unseren kostenlosen Heizungs-Check oder lassen Sie sich von unseren <a href=\"https://www.heizungsmacher.ch/ueber-uns/\" title=\"ueber-uns\">Heizexperten</a> beraten.</p>\n",
    "link": {
        "type": "link",
        "id": "7p7eaG5GRI8A1fElOkNxIb",
        "updatedAt": "2022-06-28T08:23:33.929Z",
        "label": "Online Heizungs-Check",
        "url": "https://www.heizungsmacher.ch/konfigurator"
    }
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
